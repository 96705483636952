
@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orienta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam&family=Ruda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');

:root{
  --font-primary: 18px;
  --font-medium: 16px;
  --font-responsive: 14px;
  --font-small:13px;
  --header-primary: 50px;
  --header-secondary: 30px;
  --header-small: 20px;

  /* spacing unit */
  --spacing-unit: 20px;
  --margin: var(--spacing-unit);
  --margin-xxl: calc(3*var(--spacing-unit));
  --margin-lg: calc(1.5 * var(--spacing-unit));
  --margin-sm: calc(0.5 * var(--spacing-unit));
  
  --padding-xxl: 20%;
  --padding-lg: 10%;
  --padding: 5%;
  --padding-sm: 2.5%;


  /* colors */
  --main-color: #FBB100;
  --primary-color: #E6801C;
  --blue: #1C7DE4;
  --red: #D5511C;
  --white:#F5F5F5;
  --grey:#D9D9D9;
  --pink: #C34068;
}
html {
  scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Schibsted Grotesk', sans-serif;
    /* font-family: 'Orienta', sans-serif;  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background: #FBB100;
  border-radius: 10px;
}
