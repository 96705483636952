@import "./variables.scss";

.header {
    background: $dark;
    
    height: $headerHeight;
    padding: 0 1.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    /* make sure stick above images */
    z-index: 999;

    @include breakpoint(md) {
        // height: $headerHeight - 30;
        padding: 0 2rem;
    }
    transition: 0.3s ease all;

    &.scrolling{
        transform: translateY(-100%);
    }
    .content {
        overflow: hidden;
        color: $light;
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1000;

        .content__logo {
            width: 10rem;
            font-weight: bold;
            text-decoration: none;
            color: $light;
        }

        .content__nav {
            top: 0;
            right: 100%; /* Change to 0 instead of 100% */
            bottom: 0;
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: $dark;
            backdrop-filter: blur(2px);
            transform: translate(0); /* Use translateX instead of translate */
            transition: 0.3s ease transform;
          
            @include breakpoint(md) {
                
              transform: none;
              flex-direction: row;
              background: transparent;
              width: auto;
              height: 100%;
              position: static;
            }
            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: $spacing-lg;
                @include breakpoint(md) {
                   
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: calc(0.5rem + #{$spacing-md});
                }
                li {
                    font-size: 0.8rem;
                    &:not(:last-child) {
                        margin-bottom: $spacing-lg;
                        @include breakpoint(md) {
                           
                            margin-bottom: 0;
                            // margin-right: $spacing-md;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: $light;
                        padding: 0.75rem 1.1rem;
                        border-radius: $borderRadius;
                        transition: 0.8s ease all;

                        &:hover {
                            color:$main;
                        }
                    }
                    a.active{
                        color: $main;
                    }

                }
            }
            button {
                cursor: pointer;
                outline: none;
                padding: 0.75rem 1.25rem;
                border-radius: $borderRadius;
                font-size: 1rem;
                font-family: inherit;
                background: $primary;
                color: $dark;
                border: 1px solid transparent;
                transition: 0.3s ease all;

                &:hover {
                    border-color: $primary;
                    background: rgba($primary, 0.1);
                    color: $primary;
                }
                &:active {
                    border-color: $primary;
                    background: linear-gradient(rgba($primary, 0.2), rgba($primary, 0.3));
                    color: $primary;
                }
            }

            &.isMenu {
                transform: translate(100%); /* Use translateX to slide in the menu */
              }
        }

        .content__toggle {
            color: $main;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 2rem;
            transition: 0.3s ease all;
            position: relative;

            &:hover {
                color: $primary;
            }

            @include breakpoint(md) {
                display: none;
            }
        }
    }
}

.ask__invite{
    background: $main;
    color: $dark;

    &:hover{
        background: $dark;
        color: $main !important;
    }
}


.active{
    color: red;
}