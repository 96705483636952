@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

$Poppins: "Poppins", sans-serif;

// colors
$dark: rgb(8, 8, 8);
$light: #fff;
$primary: rgb(37, 36, 36);
$bg: rgb(244, 244, 255);
$main: #FBB100;

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 40px;

// header height
$headerHeight: 100px;

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 768px) {
            @content;
        }
    }
}