body {
  background: #F5F5F5;
}

.events-landing{
  background: #F5F5F5;
  position: relative;
  color: #fff;
  height: 630px;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url('../../images/eventsimg.jpeg');
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.6s ease;

    &::before {
      content: "";
      position: absolute;
      background: rgba(195, 64, 104, 0.80);
      width: 370px; /* Adjust the width of the overlay */
      margin: 0 auto;
      height: 100%; /* Adjust the height of the overlay */

      
  }

  @media (max-height: 700px) {
    height: 500px !important;
  }

    @media (max-width: 576px) {
      
      &::before{
        width: 220px;
      }
    }


    @media screen and (max-width: 575.98px) {
      height: 250px;
      margin: 10px;
    }

    & p {
      width: 200px;
      font-size: var(--font-primary);
      z-index: 9;
    }
}
.events-title{
  font-family: Risque;
    font-size: 100px;
    z-index: 9;

    @media screen and (max-width: 576px) {
      font-size: 50px;
    }
}
.search-btn{
  width: 400px;
height: 80px;
flex-shrink: 0;
padding: 10px;
  position: absolute;
  bottom: -40px;
  border: none;
  /* padding: 10px 50px; */
  border-radius: 10px;
background: #FFF;
outline: none;

    @media screen and (max-width: 575.98px) {
      width: 300px;
    }
    @media screen and (max-width: 400px) {
      width: 250px;
    }
}




/* explore events */
.explore-events{
  background: #F5F5F5;
  /* text-align: center; */
}
.exploreevents-title{
  color: #000;
text-align: center;
font-size: 30px;
font-weight: bold;
margin: 100px 0 40px 0;
}
.categories{
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-wrap: wrap;
  
}
.simple-column{
  width: 33%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  & h4 {
    margin: 10px;
    font-weight: bold;
  }
  & p {
    @media (max-width: 576px) {
      font-size: var(--font-responsive);
    }
    @media screen and (max-width: 768px) {
      width: 80%;
    }
  }
}
.circle{

  width: 200px; /* Adjust the size as needed */
  height: 200px; /* Adjust the size as needed */
  border-radius: 50%; /* Makes the container circular */
  overflow: hidden; /* Clips the image to the circular shape */

  & img {
    width: 100%; /* Makes the image fill the container */
  height: 100%; /* Makes the image fill the container */
  object-fit: cover; /* Scales the image to cover the container */
  }
}




.events-list {
    padding: 2.5% 5%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust column widths as needed */
    gap: 20px;
  }
  
  .event-card {
    border: 1px solid #FBB100;
    padding:5px;
    text-align: center;
  }
  
  .events-image {
    max-width: 100%;
    height: auto;
  }
  .event-header{
    margin-top: 5%;
  }

  .event-btn{
    padding: 2% 4%;
    background: #fff;
    border: 1px solid #FBB100;
    border-radius: 20px;
    margin: 5%;
    transition: 0.8s;

    &:hover{
        background: #FBB100;
        /* color: #fff; */
    }
  }
  .center {
    width: 100%;

    @media screen and (max-width: 576px){
      /* display: none; */
    }
  }


  
  /* upcoming events styles */
  .upcoming-events{
    text-align: center;
  }
  .upcomingevents-title{
    font-size: 30px;
    /* font-weight: bold; */
    margin: 5%;
  }
 .eventcarousel-container{
  position: relative;
 }
  .carousel-div{
    position: relative;
    width: 100%;
   
  }
  .slider-arrow {
    position: absolute;
    top: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 75px;
    /* height: 100%; */
  width: 100%;
  z-index: 1;


    @media screen and (max-width: 576px) {
      display: none;
    }

  }
  .arrow-btn{
    cursor: pointer;
  }
  .prev {

  }
  .next{

  }
  .arrow-left{
    color: #fff;
    font-size: 2em;
  }
  .arrow-right{
    color: #fff;
    font-size: 2em;
  }



  .carousel-content{
    margin: 10px;
    border-radius: 10px;
    background: #000;
    /* width: 90%; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 576px) {
      margin: 2px;
    }
  }
  .carousel-img{
    padding: 2.5%;
    width: 50%;
    height: 450px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .carousel-description{
    padding: 5%;
    width: 50%;
    text-align: start;
    color: #fff;

    & h4 {
      font-size: 25px;
      margin-bottom: 5%;
    }

    @media  (max-width: 768px) {
      width: 100%;
    }
  }
  .carousel-btn{
    position: relative;
    padding: 10px;
    border-radius: 10px;
    background: #FBB100;
    transition: 0.6s ease;
    z-index: 9;

    &:hover{
      background-color: #b67c00;
    }
  }








  /* Featured events styling */
  .featured-container{
    /* padding: 0;
    margin: 0; */
  }
  .featured-events{
    width: 100%;
    margin: 50px 0;
  }
  .featured-title{
    text-align: start;
    padding-left: 15px;
    /* margin-left: 15%; */
    border-left: thick solid #FBB100;
  }
  .featured-div{
    /* height: 60vh; */
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;

    @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

  }
  .one {
    grid-column: span 2;
    grid-row: span 2;

    @media only screen and(max-width: 992px) {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
  .two {
    grid-column: 3;
    grid-row: 1;

    @media only screen and (max-width: 992px) {
      grid-column: 1;
      grid-row: 3;
    }
  }
  .three {
    grid-row: 1;
    grid-column: 4;

    @media only screen and (max-width: 992px) {
      grid-column: 2;
      grid-row: 3;
    }
  }
  .four {
    grid-column: 3;
    grid-row: 2;

    @media only screen and (max-width: 992px) {
      grid-column: 1;
      grid-row: 4;
    }
  }
  .five {
    grid-column: 4;
    grid-row: 2;

    @media only screen and (max-width: 992px) {
      grid-column: 2;
      grid-row: 4;
    }
  }
.mainfeature-container{
 padding: 20px;


 @media (max-width: 576px) {
  padding: 10px;
 }
 
}
.mainfeature-content{
  position: relative;
  /* padding: 5%; */
}
.featuretext-overlay{
  border-radius: 10px;
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: rgba(0, 0, 0, 0.473);
  color: white;
  padding: 5%;

  & h4 {

  }
  & p {
    color: #FBB100;
    font-weight: 600;


    @media screen and ( max-width: ) {
      
    }
  }
}
.mainfeature-container img{
  border-radius: 10px;
  width: 100%;
}
.feature-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.feature-container img{
  border-radius: 10px;
  /* position: relative; */
  height: 100%;
  width: 100%;
}
  .featured-btn{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 30px;
    color: #fff;
    width: 150px;
height: 55px;
flex-shrink: 0;
border: none;
border-radius: 10px;
background: #FBB100;
transition: 0.4s ease;

    &:hover{
      background: #000;
    }
  }
  



/* Trending Events styles */
.trendingevents-container{
  /* margin: 0;
  padding: 0; */
}
.trending-events{
  margin: 120px 0;

  @media screen and (max-width: 992px){
    /* margin: 15% 0; */
  }
}
.trending-title{
 text-align: end;
 margin: 50px 0 15px 0;
 padding-right: 15px;
 margin-right: 10%;
 border-right: thick solid #FBB100;

 
}
.trendingevents-content{
  /* width: 100%; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.event-div{
  margin-top: 10px;
  display: flex;
}
.trendingevent-title{
  color: #C4C4C4;
  margin-right: 10px;
font-size: 20px;
font-style: normal;
font-weight: 900;
  writing-mode: vertical-rl; /* Vertical text orientation */
      text-orientation: mixed; /* Controls upright text */
      transform: rotate(180deg); /* Rotate text 180 degrees to make it vertical */

      @media (max-width:576px) {
        margin: 0;
      }
}
.image-container {
  position: relative;
  /* display: inline-block; */
}

.image-container img {
  border-radius: 10px;
  width: 350px;
height: 300px;

@media (max-width: 350px) {
  width: 230px !important;
  height: 180px !important;
}

  @media (max-width:  767.98px) {
    width: 250px;
  height: 200px;
  }
}

.text-overlay {
  border-radius: 10px;
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: rgba(0, 0, 0, 0.473);
  color: white;
  padding: 10px;

    @media (max-width: 576px) {
      padding: 5px;
    }

  & h4 {

    @media (max-width: 300px) {
      font-size: var(--font-responsive) !important;
    }

    @media (max-width: 576px) {
      font-size: var(--font-medium);
    }
  }
  & p {
    color: #FBB100;
    font-weight: 600;

    @media (max-width: 576px) {
      font-size: var(--font-small);
    }
  }

  @media screen and (max-width: 992px) {
    /* font-size: 10px; */
  }
}
  










  /* Bookings page styles */
  .bookingpage-div{
    /* position: relative; */
    height: 100vh;
  }
  .contact-details{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #FBB100; */
    width: 60%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.10);


    @media screen and (max-width: 576px) {
      box-shadow: none;
   }
  }
  .form-header{
    margin: 10px;
  }
  .input-div{

    & input {
      padding: 10px 30px;
      margin: 10px;
      border:none;
      outline: none;
      border-bottom: 1px solid #FBB100;
    }
  }
  .cashout-btn{
    margin: 10px;
    padding: 10px;
    border:none;
      outline: none;
      /* border-bottom: 1px solid #FBB100; */
      background: none;
      transition: 0.6s ease-in-out;

      .cashout-icon{
        font-size: 1.5rem;
        transition: transform 0.2s ease-in-out;
        }

      &:hover{
        /* border-bottom: 1px solid #000; */

        .cashout-icon{
          transform: translateX(5px);
          }
      }
  }