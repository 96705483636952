html,
body {
  height: 100%;
  overflow-x: hidden;
}

.section-one{
  margin-bottom: 20px;
}
.firstbox-div{
    position: relative;
    margin: 50px;
    height: 630px;
    background: url('../../images/wepik-photo-mode-2022118125051.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.6s ease;

    @media (max-height: 700px) {
      height: 500px !important;
    }
  
    @media (max-width: 575.98px) {
      height: 250px;
      margin: 10px;
    }
}
.div-content{
    position: relative;
    height: 80%;
    
    overflow: hidden;

    @media screen and (max-width: 575.98px) {
      height: 90%;
    }
    
}
.div-text{
    position: absolute;
    width: 40%;
    top: 30%;
    right: 5%;
    color: #fff;

    @media screen and (max-width: 575.98px) {
        width: 45%;
    }
    @media screen and (max-width: 767.98px) {
      top: 20%;
        right: 5%;
    }

    & h2{
        font-size: 4em;
        font-family: 'Risque', cursive;

        @media screen and (max-width: 575.98px) {
         font-size: 2.5em;
         
        }
        
    }
    & p {
        font-size: 2em;

        @media screen and (max-width: 575.98px) {
          font-size: 0.9em;
        }
       
    }
}
.search-filter-container {
  margin: 0 50px;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
  }
  
  .search-bar input {
    width: 100%;
    height: auto;
    padding: 8px;
    border: 1px solid #505D69;
    border-radius: 30px;
    outline: none;
  }

  
  .filter-dropdown select {
    width: 100%;
    height: auto;
    padding: 8px;
    border: 1px solid #505D69;
    border-radius: 30px;
    outline: none;
  }
  
  @media screen and (max-width: 767.98px) {
    .search-filter-container {
      flex-direction: column;
    }
  }
  
  .thebox-rows{
    padding:10px 50px;
  }



  /* carousel section styling */
  .carousel-container {
    width: 100%;

  }
  
  .carousel-item {
    /* width: 270px;
    height: 400px; */
    display: inline-block;
    padding: var(--padding-sm);
  }
  

  .carousel-image {
    width: 300px;
    height: 400px;
    border-radius: 8px; /* Add rounded corners if desired */
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.096);
  }






  /* Webinar Card styling */
  .webinar-card{
    width: 100%;
    height: 450px;
    /* padding: 0 1.5rem; */
    border-radius: 10px;
    box-shadow: 1px 1px 1px 2px rgba(202, 133, 6, 0.178);

    & video {
      border-radius: 10px;
    }
 
  }
  .webinar-details{
    padding: 10px;

    & h3 {
      font-weight: bold;
    }
  }
  .webinar-date-time{
    color: #A4A0A0;
    /* font-family: Roboto; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
  .webinar-stats{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .price-section{
    display: flex;
    justify-content: space-between;
  }
  .price-tag{
    color: #10D876;
    /* font-family: Roboto; */
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    /* line-height: normal; */
  }
  .masterclass-title{
    font-size: 20px;
  }
  .masterclass-level{
    margin-top: 20px;
    /* color: #A4A0A0; */
    /* font-family: Roboto; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
 

  .upcoming-webinar{
    /* width: 350px; */
    /* height: 577px; */
    /* margin:0 20px; */
    overflow:"hidden";
    /* padding-right:0.1rem; */

    & img {
      width: 100%;
      border-radius:10px;
    }
  }