.main-footer{
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
   
}

.bg-color{
    position: absolute;
    width: 100%;
    height: 420px;
    background: rgb(0, 0, 0);

    @media screen and (max-width: 576px) {
      height: 40vh;
    }
}
.footer-row{
    padding: 0 10%;
    color: #fff;

    @media screen and (max-width: 960px) {
        padding: 0 5%;
     }
}
.col-one{
    position: relative;
    top: 20px;   
}
.need-help{
    font-size: 50px;
    margin-top: 10%;

    @media screen and (max-width: 768px) {
        margin-top: 0;
        font-size: var(--header-secondary);
      }
    
}
.shape {
    position: relative;
    width: 340px;
    height: 341px;
    background: #FBB100;
    box-shadow: 0px 1px 50px 1px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    color: #fff;
    margin-top: 10%;
    margin-left: 5%;

    @media screen and (max-width: 576px) {
        margin: 0;
      }

      @media screen and (max-width: 768px) {
        margin-top: 15%;
         width: 240px !important;
         height: 280px !important;
      }

      @media screen and (max-width: 960px) {
        /* margin-top: 20%; */
        width: 300px;
         height: 300px;
      }
}
.shape-text{
    width: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    & h4 {
        padding: 10px 0;
        font-size: 1.8rem;
        font-weight: 600;
    }
    & p {
        font-size: 1.1rem;
    }
}

.col-two{
    position: relative;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
     padding-top: 80px;
    }

    @media screen and (max-width: 960px) {
      left: 50%;
    }

    & p {
        font-size: 25px;

        @media screen and (max-width: 480px) {
            font-size: 10px;
            margin-top: 30px;
          }
    
          @media screen and (max-width: 768px) {
            font-size: 15px;
            color: #000;
          }
    
          @media screen and (max-width: 960px) {
            font-size: 20px;
          }
    }

    & h2 {
        color: #FBB100;
        font-size: 45px;
        font-family: 'Rock Salt', cursive;

        @media screen and (max-width: 960px) {
            font-size: 30px;
            /* color: #000; */
          }
    }
}

.ask-invite{
    text-align: center;
    background: #FBB100;
    border-radius: 50px;
    width: 200px;
    margin: 50px 0;
    padding: 10px;
    transition: 0.6s ease;

    &:hover{
        background: #fff;
        color: #FBB100;
        border: 1px solid #FBB100;
    }

    @media screen and (max-width: 960px) {
        width: 150px;
        font-size: 13px;
      }
    }

    .company-socials{
      width: 50%;
      display: flex;
      justify-content: space-between;

      & a {
        color: #FBB100;
        transition: 0.5s ease;
      }

      & a:hover{
        color: #616161;
        transform: scale(1.2);
      }


      @media screen and (max-width: 576px) {
        width: 70%;
      }
    }
