body {
  background-color: var(--white) !important;
  height: 100%;
}

/* //landing page styles */
.blog-landing{
    position: relative;
    margin: 50px;
    height: 630px;
    background: #000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.6s ease;

    @media (max-height: 700px) {
      height: 500px !important;
    }
  
    @media (max-width: 575.98px) {
      height: 250px;
      margin: 10px;
    }
}

.landing-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
  
}
.text-content{
    position: absolute;
    left: 20%;
    z-index: 99;

    & h2 {
        font-family: Risque;
        font-size: 100px;
        text-align: center;

        @media screen and (max-width: 576px) {
            font-size: 50px;
          }
    }

}
.image-one{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

}
.overlay{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.815); 
}
.image-two{
    position: absolute;
    right: 0;
    height: 100%;
}

.search-btn{
    width: 400px;
    height: 80px;
    flex-shrink: 0;
    padding: 10px;
    position: absolute;
    bottom: -40px;
    border: none;
    border-radius: 10px;
    background: #FFF;
    outline: none;
  
      @media screen and (max-width: 575.98px) {
        width: 300px;
      }
      @media screen and (max-width: 400px) {
        width: 250px;
      }
  }




/* 
  Explore section styles */
  .explore-div{
    position: relative;
    text-align: center;
    height: 100%;
    margin: 50px;

    @media screen and (max-width: 575.98px) {
      margin: 30px;
  }

    & h3 {
        font-weight: bold;
        margin: var(--margin);
    }
  }
  .explore-content{
    position: relative;
    display: flex;
    align-items: center;
    height: 50vh;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      height: 100vh;
    }
  }
  .explore-header{
    position: relative;
    height: 400px;
    z-index: 9;

    & img {
      position: absolute;
      left: 0;
      width: 250px;
      height: 100%;
    }
  }
  .explore-text{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    position: relative;
    top: 50%;
    left: 20%;
    transform: translateY(-50%);

    & h4 {
      color: var(--primary-color);
    }

    & p {
      width: 80%;
    }
  }

  .explore-carousel{
    position: relative;
    width: 80%;
    height: 100%;

    @media (max-width:768px) {
      width: 100%;
      margin: 50px 0 0 0;
    }
    
  }

  .explorecarousel-container{
   position: relative;
  }
  .explorecarousel-div{
    position: relative;
    padding: 10px;
    
  }
  .explorecarousel-item{
    width: 350px;
    height: 450px;
    background: #fff;
    border-radius: 10px;
    padding: 0;
  

    & img {
      border-radius: 10px;
      width: 100%;
    }
    & h4, p {
      text-align: start;
      margin: var(--margin-sm);
    }
  }
  .type-date{
    display: flex;
    justify-content: space-between;
    margin: var(--margin-sm);
  }
  .explore-category{
    border-radius: 10px;
    background: #FFEAD6;
    padding: 0 5px;
    color: #FEA14A;
  }
  .explore-slider-arrow {
    position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 65px; */
    height: 100%;
  width: 100%;
  z-index: 999;

    @media screen and (max-width: 768px) {
      display: none;
    }

  }
  .explore-arrow-btn{
    cursor: pointer;
  }
  .arrow-prev {
    float: left;
    padding-left: 20px;
  }
  .arrow-next{
   right: 0;
   float: right;
   padding-right: 150px;

   @media (max-width: 768px) {
    margin-right: 0;
   }
  }
  .explore-arrow-left{
    color: #fff;
    font-size: 2em;
  }
  .explore-arrow-right{
    color: var(--primary-color);
    font-size: 2em;
  }








  /* featured blogs styles */
  .blogfeatures-section{
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--margin-lg);

    & h4 {
        text-align: end;
        padding-right: 15px;
        border-right: thick solid #FBB100; 
    }
  }
  .view-more{
    color: var(--white);
    background: var(--main-color);
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: var(--margin);
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }


  .featured-content{
    position: relative;
  }
  .blog-featured-container{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .blog-featured-div{
    position: relative;
    margin-top: 10px;
    display: flex;
  }
  .featuredblog-category{
    color: #C4C4C4;
    margin-right: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
    writing-mode: vertical-rl; /* Vertical text orientation */
        text-orientation: mixed; /* Controls upright text */
        transform: rotate(180deg); /* Rotate text 180 degrees to make it vertical */
  
        @media (max-width:576px) {
          margin: 0;
        }
  }
  .blog-image-container{
    position: relative;
    width: 250px;
  height: 250px;
  

  @media (max-width: 350px) {
    width: 230px !important;
    height: 180px !important;
  }
  
    @media (max-width:  767.98px) {
      width: 250px;
    height: 200px;
    }
  }
  .blog-image-container img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  
  
  }
  .blog-text-overlay {
    border-radius: 10px;
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -0%);
    background-color: rgba(0, 0, 0, 0.473);
    color: white;
    padding: 10px;
  
      @media (max-width: 576px) {
        padding: 5px;
      }
  
    & h3 {
      font-size: var(--header-small);
  
      @media (max-width: 300px) {
        font-size: var(--font-responsive) !important;
      }
  
      @media (max-width: 576px) {
        font-size: var(--font-medium);
      }
    }
  
  }


  /* mostread-div styles */
  .mostread-div{
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--margin-lg);

    & h4 {
        text-align: start;
        padding-left: 15px;
        border-left: thick solid #FBB100; 
    }
  }


  /* trending blogs section styles. */
  .trendingblogs-div{
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--margin-lg);

    & h4 {
        text-align: end;
        padding-right: 15px;
        border-right: thick solid #FBB100; 
    }
  }