

.first-div{
  position: relative;
  height: 630px;
  margin: 50px;
  background: url('../../images/tukku-magi-1.png') ;
  background-size: cover;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  overflow-x: hidden;

  @media (max-height: 700px) {
    height: 500px !important;
  }

  @media (max-width: 575.98px) {
    height: 250px;
    margin: 10px;
  }
}
.first-div::before{
content: "";
width: 80%;
position: absolute;
left: 0; right: 0;
top: 0; bottom: 0;
background: rgb(28, 125, 228, 0.8);
}


.firstdiv-text{
  position: absolute;
  color: #FFF;
  z-index: 20;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h1{
      font-family: 'Risque', cursive;
      font-size: 100px;

      @media (max-width: 350px) {
        font-size: 30px !important;
      }

      @media (max-width: 575.98px) {
        font-size: 40px;
      }
   
  }
  & p {
      color: #FFF;
      font-size: 20px;
      width: 300px;

      @media (max-width: 350px) {
        width: 150px !important;
        font-size: var(--font-small) !important;
      }

      @media (max-width: 575.98px) {
        font-size: var(--font-responsive);
      }
      @media (max-width: 768px) {
        width: 200px;
      }
     
  }
}
.service-btn{
  text-decoration: none;
  color: #000;
  text-align: center;
  width: auto;
  margin-top: var(--margin-sm);
  padding: 10px 15px;
  border-radius: 50px;
  background: var(--main-color);
  transition: 0.4s ease;
  font-size: var(--font-medium);

  &:hover{
    color: #fff;
    background: #000;
  }

  @media (max-width:  767.98px) {
    font-size: var(--font-responsive);
    padding: 10px;
  }
}
.service-image{
  position: absolute;
  height: 100%;
  right:0%;
  overflow: none;
  z-index: 10;
  transition: 0.8s ease-in-out;


  @media (max-width: 575.98px) {
    width: 300px;
  }
  
  @media (max-width:  767.98px) {
    left:40%;
  }
  @media (max-width: 1200px) {
    right:-40%;
  }
}



/* second div styling */
.second-div{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  width: 100%;

}
.seconddiv-text{
  position: relative;
  margin-top: var(--margin);
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
  text-align: center;
  font-size: var(--font-primary);

  @media (max-width: 575.98px) {
    padding: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   font-size: var(--font-small) !important;
  }

  @media (max-width: 767.98px) {
   font-size: var(--font-responsive);
  }
}
.services-buttons{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

}
.service-links{
  color: var(--white);
  text-decoration: none;
  padding: 0 10px;
  width: 33.3%;

  
}
.creator-services{
  border-radius: 0 10px 10px 0;
  background-color: var(--pink);

  &:hover{
    background-color: #c34067e3;
  }
}
.corporate-services{
  background-color: var(--blue);

  &:hover{
    background-color: #1c7de4e3;
  }
}
.investor-opportunities{
  border-radius: 10px 0 0 10px;
  background-color: var(--primary-color);

  &:hover{
    background-color: #e6811ce7;
  }
}

/* ----------------------------------------------------------------- */
/* third section styling */
.image-containertwo{
position: relative;
}
.corporate-design{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: var(--margin);
}
.wallpaper{
transition: 0.4s ease;

  @media screen and (max-width: 568px) {
    height: 800px;
  }
}
.serviceimg{
width: 85%;
height: auto;
position: absolute;
top: -20%;
left: -20%;
z-index: 10;
transition: 0.4s ease-in-out;

@media (max-width: 768px) {
 width: 75%;
}

@media (max-width: 1199.98px) {
  width: 75%;
  
}
}
.corporate-square{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 450px;
height: 530px;
background: #1C7DE4;
border-radius: 20px;
text-align: center;
z-index: 10;

@media (max-width: 350px) {
  width: 270px !important;
}

@media (max-width: 568px) {
  width: 350px;
  height: 450px;
}
& h5{
  font-size: var(--header-secondary);
  color: #fff;
  margin-top: 10px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: var(--header-small);
  }
}
& p{
  margin-top: var(--margin);
  font-size: var(--font-medium);
  color: #FFF;
  position: relative;
  padding-left: var(--padding);
  padding-right: var(--padding);

  @media (max-width: 350px) {
    font-size: var(--font-small) !important;
  }

  @media (max-width: 768px) {
    font-size: var(--font-responsive);
  }
}
}
.square-content{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.corporate-list{
  margin-top: var(--margin-sm);
  text-align: start;
  font-size: var(--font-responsive);
  list-style: none;

  @media (max-width: 350px) {
    font-size: var(--font-small) !important;
  }}


.fourth-div{
margin: 50px 0;
}
.corporate-btn{
  font-size: var(--font-responsive);
  text-align: center;
  width: auto;
  padding: 5px 15px;
  border: none;
  border-radius: 50px;
  color: #fff;
  background: #FBB100;
    transition: 0.6s ease-in-out;

    .cashout-icon{
      font-size: 1.6em;
        transition: transform 0.2s ease-in-out;
    }
    &:hover{

      .cashout-icon{
        transform: translateX(5px);
        }
    }

}

/* -------------------------------------------------------- */




/* ------------------------------------------------------------------------------------ */

/* fifth section styling */

.fifth-div{
  overflow: hidden;
  margin-top: var(--margin-xxl);

  @media (max-width: 576px) {
    margin-top: var(--margin);
  }
}

.rectangle-image1{
  position: absolute;
  top: 0;
  left:0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: 0.4s ease-in-out;
}
.rectangle-image2{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 80%;
height: auto;
transition: 0.4s ease-in-out;
}
.creatorservices-col{
  position: relative;
}

.fifth-content{
  width: 80%;
    margin-left: auto;
      font-size: var(--font-primary);


    @media (max-width: 768px) {
      width: 100%;
      position: relative;
    }
  

    & h5 {
      font-size: var(--header-secondary);
      font-weight: 600;
      color: #FBB100;
    }
    & p{
      margin-top: var(--margin);
      color: #000;
      position: relative;
  
      @media (max-width: 350px) {
        font-size: var(--font-small) !important;
      }

      @media (max-width: 768px) {
        width: 90%;
        font-size: var(--font-responsive);
        margin-top: var(--margin);
        margin-left: auto;
        margin-right: auto;
      }
    }
}
.fifthdiv-image{
  

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.creator-list {
  font-size: var(--font-medium);
  list-style: none;

  @media (max-width: 350px) {
    font-size: var(--font-small) !important;
  }

  @media (max-width: 768px) {
    font-size: var(--font-responsive);
  }

}
.btn-three{
  font-size: var(--font-medium);
  text-decoration: none;
  color: #fff;
  margin-top: var(--margin);
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background: #FBB100;
  transition: 0.4s ease;

  &:hover{
    color: #fff;
    background: #000;
}

  @media screen and (max-width: 576px) {
    margin-top: var(--margin-sm);
      margin-left: 5%;
    }
}


.section-five {
  position: relative;
  margin: 50px 0 250px 0;
  

  @media screen and (max-width: 768px) and (min-height:800px){
      height: 100vh;
      overflow: hidden;
      margin: 50px 0 1px 0;
     }

     @media screen and (max-width: 768px) and (max-height:800px){
      height: 120vh;
      overflow: hidden;
      margin: 50px 0 1px 0;
     }

     @media screen and (max-width: 576px) {
      margin: 0;
     }
}
.ev-row{
  margin-left: 10%;

  @media screen and (max-width: 992px) {
    margin: 0;
   }
}
.extravalue-text{
  flex-direction: column;
  color: #000;
  font-size: var(--font-primary);
  position: relative;

     @media screen and (max-width: 992px) {
      font-size: var(--font-responsive);
      margin-left: 0;
     }
}
.s5-title{
  color: #FBB100;
font-size: var(--header-primary);
font-weight: 600;
border-bottom: #FBB100 1px solid;
width: 300px;
margin-bottom: 10px;

 @media screen and (max-width: 992px) {
  font-size: 55px;
  margin-bottom: 10px;
 }
}
.interested-btn{
  text-align: center;
  color: #fff;
  background: #FBB100;
  border-radius: 50px;
  width: 200px;
  margin: 30px 0;
  padding: 10px;
  transition: 0.8s ease;

  &:hover{
      color: #fff;
      background: #000;
      border: 1px solid #FBB100;
  }
}
.joinlist-btn{
  color: #1C7DE4;
  font-size: 16px;
}

.investor-section{
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  
  @media (max-width: 576px) {
    width: 100%;
  }
}
/* Investor section styling */
.investorvideo-div{
  margin-top: var(--margin-lg);
  display: flex;

  @media (max-width:576px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  

    @media screen and (max-width: 992px) {
      flex-wrap: wrap-reverse;
    }
}
.investor-content{
  width: 20%;
  margin-top: var(--margin) !important;

  

  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: var(--font-responsive);
  }

  & p {

    @media (max-width:576px) {
      font-size: var(--font-small);
    }
  }
}
.contact-us{
  border-radius: 50px;
  background: #FBB100;
  padding: 5px 15px;
  border: none;
  transition: 0.6s ease;

  &:hover{
    background: #000;
    color: #fff;
  }
}
.embedded-video{
  margin-left: 50px;
  width: 80%;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin: 0;
  }
}


.motivation-text{
  margin-top: var(--margin-xxl);
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 576px) {
    margin-top: var(--margin-lg);
    height: 15vh;
  }

  & p {
    font-size: var(--font-primary);
    font-family: 'Kalam', cursive;
    width: 80%;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 350px) {
      font-size: var(--font-small) !important;
    }
  }
}
.fillform-text{
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-height: 700px) {
    margin-top: 50px !important;
  }

  @media screen and (max-width: 768px) {
    height: 25vh;
  }

  & p {
    font-size: var(--font-primary);
    width: 80%;
    text-align: center;


    @media (max-width: 350px) {
      font-size: var(--font-small) !important;
    }

    @media screen and (max-width: 768px) {
      font-size: var(--font-responsive);
      width: 100%;
      padding: var(--padding);
    }
  }
}