.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  /* opacity: 0; */
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 9999;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}
