

/* Creativeminds stylings goes here */
.creativeminds-div{
    position: relative;
    display: flex;
    margin-top: var(--margin-xxl);

    @media (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: var(--margin);
    }
}
.creativeminds-title{
    position: relative;
    width: 1000px;
    height: 650px;
    background-image: url('../../images/d-wallpaper-hd-wallpaper-i-phone-wallpaper-android-wallpaper-wallpaper-4-k-1.png');
    background-size: cover;
    background-repeat: none;

    @media (max-width: 768px) {
        width: 100%;
        height: 350px;
        margin: 0 var(--margin);
    }
}
.creativeminds-overlay{
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 90%;
    height: 90%;
    right: 0;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding);

    & h3 {
        margin: var(--margin) 0;
        font-size: var(--header-secondary);

        @media (max-width: 768px) {
           font-size: var(--header-small);
        }

        @media (max-width:320px) {
            font-size: 16px !important;
        }
    }
    & p {
        font-size: var(--font-primary);

        @media (max-width: 768px) {
            font-size: var(--font-small);
        }

        @media (max-width:320px) {
            font-size: 12px !important;
        }
    }
}
.creativeminds-form{
    margin-left: var(--margin);
    margin-right: var(--margin);
    background-color: var(--primary-color);

    & form {
        margin: var(--margin-lg);

        & label {
            margin-top: var(--margin-sm);
            color: var(--white);
        }

        & input, textarea, button{
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            border-radius: 30px;
            border: none;
            padding: 10px 0;
        }

        & button {
            margin-top: var(--margin-sm);
            background-color: var(--main-color);
            transition: 0.4s ease;
            text-transform: uppercase;
            color: var(--white);

            &:hover{
                background-color: black;
            }
        }
    }
}



/* visionary corporate section styling */
.visionarycorporate-div{
    position: relative;
    display: flex;
    margin-top: var(--margin-xxl);

    @media (max-width: 768px) {
        flex-wrap: wrap-reverse;
    }
}
.visionarycorporate-title{
    position: relative;
    width: 1000px;
    height: 650px;
    background-image: url('../../images/blog3.jpeg');
    background-size: cover;
    background-repeat: none;

    @media (max-width: 768px) {
        width: 100%;
        height: 450px;
        margin: 0 var(--margin);
    }
}
.visionarycorporate-overlay{
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 90%;
    height: 90%;
    right: 0;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding);

    & h3 {
        margin: var(--margin) 0;
        font-size: var(--header-secondary);

        @media (max-width: 768px) {
           font-size: var(--header-small);
        }

        @media (max-width:320px) {
            font-size: 16px !important;
        }
    }
    & p {
        font-size: var(--font-primary);

        @media (max-width: 768px) {
            font-size: var(--font-small);
        }

        @media (max-width:320px) {
            font-size: 12px !important;
        }
    }
}
.visionarycorporate-form{
    margin-left: var(--margin);
    margin-right: var(--margin);
    background-color: var(--primary-color);

    & form {
        margin: var(--margin-lg);

        & label {
            margin-top: var(--margin-sm);
            color: var(--white);
        }

        & input, textarea, button{
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            border-radius: 30px;
            border: none;
            padding: 10px 0;
        }

        & button {
            margin-top: var(--margin-sm);
            background-color: var(--main-color);
            transition: 0.4s ease;
            text-transform: uppercase;
            color: var(--white);

            &:hover{
                background-color: black;
            }
        }
    }
}



/* invite text stylings */
.invite-text{
    height: 350px;
    /* width: 400px; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 576px) {
        height: 450px;
    }

    & p {
        width: 80%;
        font-family: Irish Grover;
        font-size: var(--font-primary);

        @media (max-width: 768px) {
            font-size: var(--font-medium);
        }
    }
}