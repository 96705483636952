.form-div{
    position: absolute;
    text-align: center;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
  
    @media (max-width: 576px) {
      width: 90%;
    }
  }
  .form-letter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: auto;
  
    & input {
      width: 100%;
      box-sizing: content-box; /* Default value, padding and border are not included in width */
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        border: none;
        color: #000;
        outline: none;

        @media screen and (max-width: 768px) {
          width: 90%;
          padding: 15px;
        }
    }



    & button {
      margin: 10px;
      width: 80%;
      padding: 20px;
      border-radius: 10px;
      border: none;
      transition: 0.6s ease;
      background: var(--main-color);

      &:hover{
        background-color: var(--grey);
      }

      @media screen and (max-width: 768px) {
        padding: 15px;
      }
    }
  }

  .message-div {
    background: url('../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png'), 50% / cover no-repeat;
    height: 650px;
    margin-top: 20px;
    overflow: hidden;

    @media screen and (max-width: 576px) {
      height: 700px;
      /* padding: 15px; */
    }
  }

  .success-message {
    font-size: var(--font-responsive);
    color: var(--grey)
  }