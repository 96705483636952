body {
    /* overflow-x: hidden; */
}

.landpage {
    position: relative;
    height: 1100px;
    background-color: var(--main-color);
    overflow: hidden;

 
    @media screen and (max-height: 700px) {
       height: 850px !important;
    }

    @media screen and (max-width: 768px) and (min-height: 800px){
        height: 1000px;
    }
}
.main-header{
    position: absolute;
    top: 15%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 9;
    
    font-family: 'Rubik Moonrocks', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    text-align: center;
    color: var(--white);

    @media screen and (max-width: 576px) {
        left: 65%;
    }
    @media screen and (max-width: 768px) {
        top: 10%;
        font-size: 35px;
    }
    
}
.main-image-container {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }

.land-img{
    transition: 0.6s ease;

    @media screen and (max-width: 400px) and (max-height: 700px){
        width: 95%;
    }

    @media screen and (max-width: 576px){
        margin-top: 100px;
    }
}

.land-img2{
    padding: 5px;
margin-top: 350px;
transition: 0.6s ease;

@media screen and (max-width: 576px){
    display: none;
}
}

.land-img3{
margin-top: 350px;
transition: 0.6s ease;

    & hover {
    transform: scale(2);
}
    @media screen and (max-width: 768px){
        display: none;
    }
}

.btn-one {
    margin-left: 100px;
    text-align: center;
    border: 2px solid var(--white);
    border-radius: 50px;
    width: 200px;
    padding: 10px 10px;
    color: var(--white);
    font-weight: 700;
    position: absolute;
    transition: 0.8s ease;

    &:hover {
        border: 2px solid #000; 
    }
    
    @media screen and (max-width: 576px) {
        margin-top: var(--margin-lg);
        margin-left: 20px;
        width: 200px;
     }
    @media screen and (max-width: 768px) and (min-height: 800px){
        margin-top: var(--margin-xxl);
        margin-left: 50px;
        width: 200px;
    }
}



/* Cards section styling */
.section-two{
    position: relative;
    /* height: 40vh; */
    overflow: hidden;

}
.cards-section{
    margin-bottom: var(--margin-lg);
    
}
.cards-wallpaper{
    height: 70px;
    background: url('../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png');
    background-size: cover;
    background-position: center;
}
.cards-div{
    /* position: relative; */
    margin-top: var(--margin-lg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.card-details{
    margin: var(--margin);
    text-align: center;

}

.cards-one{
    flex: 1;
    margin-bottom: var(--margin);
    border-radius: 50%;
    text-align: center;
    background: var(--white);
    position: relative;
    width: 8rem;
    height: 8rem;
    outline: none;
    transition: 0.6s ease;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        width: 6rem;
        height: 6rem;
    }
    @media screen and (max-width: 480px) {
        width: 5rem;
        height: 5rem;
    }
}
.cards-source{
    border: 1px solid var(--main-color);
}
.cards-network{
    border: 1px solid var(--pink);
}
.cards-upskill{
    border: 1px solid var(--red);
}
.cards-manage{
    border: 1px solid var(--blue);
}
.cards-invest{
    border: 1px solid var(--primary-color);
}
.card-content{
    position: absolute;
    /* background-color: #000; */
    width: 90%;
    height: 90%;
    border: none;
    border-radius: 50%;

}
.source-card{
    background-color: var(--main-color);
    bottom: 0;
    left: 0;
    transition: 0.8s ease;
    &::before{
        transition: 0.8s ease;
    }

    &:hover{

        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.185); /* Change the color and opacity as needed */
            z-index: 1; /* Place it above the content */

        }
    }
}
.network-card{
    background-color: var(--pink);
    top: 0;
    right: 0;


    &:hover{
    
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.185);
            z-index: 1;

        }
    }
}
.upskill-card{
    background-color: var(--red);
    bottom: 0;
    right: 0;

    &:hover{
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.185);
            z-index: 1;

        }
    }
}
.manageprojects-card{
    background-color: var(--blue);
    top: 0;
    left: 0;

    &:hover{
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.185);
            z-index: 1;

        }
    }
}
.invest-card{
    background-color: var(--primary-color);
    bottom: 0;

    &:hover{
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.185);
            z-index: 1;

        }
    }
}
.cards-title{
    font-size: var(--font-responsive);
    font-weight: bold;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 480px) {
        font-size: 0.7rem;
    }
}
.row-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  width: 40%;
}



/* about us section stylings */
.section-three{
    position: relative;
    overflow: hidden;
}
.about-us{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-text{
    display: flex;
    flex-direction: column;
    padding-left: var(--padding-sm);
    padding-right: var(--padding);

}
.aboutus-content{
    /* width: 100%; */
    display: flex;

    & h3 {
        writing-mode: vertical-rl; /* vertical writing mode from right to left */
        transform: rotate(180deg);
        font-size: var(--header-primary);
        font-weight: 600;
        color: var(--main-color);
        text-align: center;
        margin-right: 10px;
        margin-left: 0;
        padding: 0;
    }

}
.aboutus-btn{
    /* margin-left: var(--padding); */
    align-self: start;
    font-size: 0.9rem;
    text-align: center;
    background: var(--main-color);
    border-radius: 50px;
    text-decoration: none;
    color: #000;
    margin-top: var(--margin-sm);
    padding: 10px;
    transition: 0.8s ease;

    &:hover{
        color: #fff;
        background: #000;
    }


    @media screen and (max-width: 960px) {
        font-size: 13px;
      }
}
  

/* who we serve section */
.weserve-section{
    position: relative;

    @media (max-width: 568px){
        margin-top: var(--margin);
    }
}
.weserve-div{
    width: 80%;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      width: 90%;
        
    }
}
.weserve-title{
    color: #000;
        font-size: var(--header-secondary);
        
        font-weight: 600;
        display: inline;
        
}
.weserve-content{
    display: flex;
    margin-top: var(--margin);
    

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        
    }
}
.creatives-div{
    position: relative;
    height: 420px;
    width: 50%;
    
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80%; /* Set the background color to cover 90% of the height */
        background: var(--red); /* Replace #00f with your desired background color */
        z-index: 0;
      }

      & img {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        width: 300px;

        @media screen and (max-width: 400px) {
            width: 250px;
        }
        
    }


    & p {
        color: var(--white);
        position: absolute;
        bottom: 0;
        padding: var(--padding);

        @media screen and (max-width: 576px) {
            font-size: var(--font-responsive);
        }
    }

    @media screen and (max-width: 992px) {
        height: 440px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 576px) {
        height: 400px;
    }
}
.business-div{
    position: relative;
    height: 420px;
    width: 50%;
    

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80%; /* Set the background color to cover 90% of the height */
        background-color: var(--blue); /* Replace #00f with your desired background color */
        z-index: 0;
      }

    & img {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        width: 300px;

        @media screen and (max-width: 400px) {
            width: 250px;
        }
    }

    & p {
        color: var(--white);
        position: absolute;
        bottom: 0;
        padding: var(--padding);

        @media screen and (max-width: 576px) {
            font-size: var(--font-responsive);
        }

    }

    @media screen and (max-width: 992px) {
        height: 440px;
    }

    @media screen and (max-width: 768px) {
        margin-top: var(--margin);
      width: 100%;
    }
    @media screen and (max-width: 576px) {
    height: 400px;
    }
}

.weserve-btn{
    /* margin-left: var(--padding); */
    font-size: 0.9rem;
    text-align: center;
    background: var(--main-color);
    border-radius: 50px;
    text-decoration: none;
    color: #000;
    width: 140px;
    margin-top: var(--margin-sm);
    padding: 10px;
    transition: 0.8s ease;

    &:hover{
        color: #fff;
        background: #000;
    }

    @media screen and (max-width: 960px) {
        width: 150px;
        font-size: 13px;
      }
}



/* networking section styles */
.networking-div{
    position: relative;
    
}
.networking-row{
    position: relative;
    margin-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.networking-btn{
    color: #000;
    border: none;
    background-color: var(--main-color);
    border-radius: 30px;
    padding: 5px 10px;
    font-size: var(--font-medium);
    text-decoration: none;
    text-align: center;
    width: 120px;
    margin-top: var(--margin);
    /* padding: 10px; */
    transition: 0.8s ease;

    &:hover{
        color: #fff;
        background: #000;
    }

    @media screen and (max-width: 768px) {
         margin-left: var(--padding);
      }

}

.networkrow-text{
position: relative;
color: #000;
font-size: var(--font-primary);


 & p {
    width: 80%;

    @media screen and (max-width: 992px) {
        width: 90%;
    }

    @media screen and (max-width: 768px) {
        padding-left: var(--padding);
        padding-right: var(--padding);
        width: 100%;
    }
 }
    
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: var(--font-responsive);
    /* margin-top: 100px; */
    margin-top: 30px; 
    
  }
  @media screen and (max-width: 992px) {
    width: 100%;
            font-size: var(--font-responsive);
}
}
.networkingtext-div{
    margin-top: 1%;
}
.source-text{
    margin-top: var(--margin-xxl);
    color: #000;
    font-size: var(--font-primary);


    @media screen and (max-width: 768px) {
        font-size:var(--font-responsive);
       }
       @media screen and (max-width: 992px) {
        font-size: var(--font-responsive);

       }

    .source-title{
        color: var(--main-color);
        font-size: var(--header-secondary);
        font-weight: 600;
        display: inline;
        border-bottom: 1px solid var(--main-color);
        padding-bottom: 3px;
        margin-bottom: 15px;
    }
}
.section-four{
    position: relative;
    /* overflow: hidden; */
    width: 80%;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        width: 90%;
    }
}
.talent-div{
    position: relative;
    height: 30vh;
    margin-top: var(--margin-xxl);
    /* margin: 100px 0 ; */

    
      &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        background: #000000c5;
        z-index: 2;
    }

    @media (max-height: 800px) {
        height: 40vh;
    }
    @media (max-width: 350px) {
        height: 40vh;
    }
}
.talenttitle-div{
    position: relative;
    left: 0;
    z-index: 99;
    color: #000;
    padding: var(--padding-sm);
    width: 50%;
    margin: 0;
    /* padding-right: 300px; */

   

    @media screen and (max-width: 576px) {
        margin: 0;
        /* padding-right: 50px; */
    }

    @media screen and (max-width: 768px) {
        width: 60%;
       }

       & h3 {
        font-size: var(--header-secondary);
        font-weight: bold;
        color: var(--primary-color);

        @media (max-width: 576px) {
            font-size: var(--header-small);
        }
       }

       & p {
        color: #fff;

        @media screen and (max-width: 300px) {
            font-size: var(--font-small) !important;
        }

        @media screen and (max-width: 768px) {
            font-size: var(--font-small);
        }
       }
}
.comingsoon-img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  
}
.talent-btn{
    color: #000;
    border: none;
    background-color: var(--main-color);
    border-radius: 30px;
    padding: 5px 10px;
    font-size: var(--font-medium);
    transition: 0.6s ease;
    text-decoration: none;
    /* margin-bottom: 10px; */

    &:hover{
        color: #fff;
        background-color: #000;
    }
}
.upskill-row{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
}
.upskillrow-text{
    position: relative;
    color: #000;
    font-size: var(--font-primary);
    margin-top: 50px;
    /* text-align: end; */

    & p{
        width: 80%;
        margin-left: auto;

        @media screen and (max-width: 768px) {  
            width: 100%;
            padding-left: var(--padding);
            padding-right: var(--padding);
           }
    }

    @media (max-width: 768px) {
        text-align: start;
        font-size: var(--font-responsive);
        margin-top: 60px;
       }
       @media screen and (max-width: 992px) {
        width: 100%;
        /* padding-left: 5%; */
        font-size: var(--font-responsive);
       }
}
.text-div{
    margin-top: var(--margin);
}
.upskill-btn{
    font-size: var(--font-medium);
    text-align: center;
    background: var(--main-color);
    text-decoration: none;
    color: #000;
    width: 120px;
    margin-top: var(--margin);
    margin-left: var(--padding-xxl);
    border-radius: 30px;
    padding: 5px 10px;
    transition: 0.8s ease;

    &:hover{
        color: #fff;
        background: #000;
    }

    @media screen and (max-width: 768px) {
         margin-left: var(--padding);
        /* margin: 20px 0 20px 45px; */
      }

}



/* Manage projects styling */
.manprojects-row{
    display: flex;
    margin-top: 95px;
    margin-bottom: var(--margin-lg);
    align-items: center;
    justify-content: center;
}
.manageprojects-text{
    position: relative;
    margin-right: auto;
color: #000;
font-size: var(--font-primary);


& p {
    width: 80%;


    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: var(--padding);
        padding-right: var(--padding);
    }
}

    @media screen and (max-width:568px) {
        margin-top: 60px;
        margin-bottom: 30px;
    }


  @media screen and (max-width: 768px) {
        width: 100%;
        font-size: var(--font-responsive);
    }
    @media screen and (max-width: 992px) {
        width: 100%;
            font-size: var(--font-responsive);
        }
}
.manageproj-btn{
    /* margin-left: var(--padding); */
    font-size: var(--font-medium);
    text-align: center;
    background: var(--main-color);
    border-radius: 30px;
    text-decoration: none;
    color: #000;
    width: 120px;
    margin-top: var(--margin);
    padding: 5px 10px;
    transition: 0.8s ease;

    &:hover{
        color: #fff;
        background: #000;
    }

    @media screen and (max-width: 768px) {
         margin-left: var(--padding);
      }
}
